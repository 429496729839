import { object, bool, array } from 'prop-types';
import { useState, useEffect } from 'react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './snippet-card.module.scss';

const splitArrayIntoGroups = (array, groupSize) => {
  const groups = [];
  for (let i = 0; i < array?.length; i += groupSize) {
    groups.push(array.slice(i, i + groupSize));
  }
  return groups;
}

const isTextLongerThanContainer = (text, maxLength) => {
  return text?.length > maxLength;
}

function SnippetCard({ data, fromTabCase, attorneys }) {
  // trying to display the max number of characters
  const maxDefaultContentLength = 910;
  const shortContent = data.content?.slice(0, maxDefaultContentLength);
  const lineBreaks = (shortContent?.split("<p>"))?.length;
  const contentLength = maxDefaultContentLength - (55 * lineBreaks);
  const textLongerThanContainer = isTextLongerThanContainer(data.content, contentLength);

  const [readMore, setReadMore] = useState(false);

  // removing unnecessary tags from the content.
  let contentAux = data.content?.replace(/<div> ?/g, '');
  contentAux = contentAux?.replace(/<\/div> ?/g, '');
  contentAux = contentAux?.replace(/<p> ?/g, '');
  contentAux = contentAux?.replace(/<\/p>/g, '\n');
  const snippetData = data?.caseResultSnippetSingleConfiguration;
  const lawyers = snippetData?.beCrSnippetAttorney;
  const lawyersNames = lawyers?.map((lawyer) => lawyer.title);
  const lawyersImages = [];
  lawyersNames?.forEach((lawyerName) => {
    const attorneyData = attorneys.nodes?.find((attorney) => attorney.title === lawyerName);
    if (attorneyData?.attorney?.attorneyProfileImage) {
      lawyersImages.push(attorneyData.attorney.attorneyProfileImage);
    }
  });

  const itemsPracArea = snippetData?.beCrSnippetPracticeArea;
  const itemsPracAreaGroups = splitArrayIntoGroups(itemsPracArea, 3);
  const isFeatured = snippetData?.beCrSnippetFeatured;
  return (
    <article className={styles.article}>
      <h2 className={styles.titleCard}>
        {data.title?.length > 75 ? `${data.title?.slice(0, 75)}...` : data.title} 
        <span>
          {isFeatured && (
          <svg xmlns="http://www.w3.org/2000/svg" className={styles.svg} viewBox="0 0 30 24">
            <g>
              <g transform="translate(3 18)">
                <path d="M12.6,0.3 L2.3,0.3 C1.5,0.3 0.9,0.9 0.9,1.7 L0.9,2.6 L1.8,2.6 L1.8,1.7 C1.8,1.4 2,1.2 2.3,1.2 L12.6,1.2 C12.9,1.2 13.1,1.4 13.1,1.7 L13.1,2.6 L14,2.6 L14,1.7 C14,0.9 13.4,0.3 12.6,0.3 Z" />
                <path d="M14,5.9 L0.9,5.9 C0.6,5.9 0.4,5.7 0.4,5.4 L0.4,2.6 C0.4,2.3 0.6,2.1 0.9,2.1 L14,2.1 C14.3,2.1 14.5,2.3 14.5,2.6 L14.5,5.4 C14.5,5.7 14.3,5.9 14,5.9 Z M1.4,5 L13.6,5 L13.6,3.1 L1.4,3.1 L1.4,5 Z" />
              </g>
              <path d="M29.3 14C29.2 13.6 28.9 13.4 28.6 13.2L16.9 8.3 16.5 9.1 28.3 14C28.4 14 28.5 14.1 28.5 14.2 28.5 14.3 28.5 14.4 28.5 14.5L27.5 16.6C27.4 16.8 27.1 16.9 26.9 16.8L15.9 10.3 15.5 11.1 26.5 17.5C26.7 17.6 26.9 17.7 27.2 17.7 27.7 17.7 28.2 17.4 28.4 17L29.4 14.9C29.4 14.8 29.5 14.4 29.3 14zM18 7.6C17.8 7.6 17.6 7.5 17.4 7.5L11.6 4.6C11.3 4.4 11 4.1 10.9 3.8 10.8 3.4 10.8 3.1 11 2.7L11.6 1.6C11.8 1.1 12.3.8 12.9.8 13.1.8 13.3.9 13.5.9L19.3 3.8C19.6 4 19.9 4.3 20 4.6 20.1 5 20.1 5.3 19.9 5.7L19.3 6.8C19.1 7.3 18.6 7.6 18 7.6zM12.8 1.7C12.6 1.7 12.5 1.8 12.4 2L11.8 3.1C11.7 3.2 11.7 3.3 11.8 3.5 11.8 3.6 11.9 3.7 12 3.8L17.8 6.7C18 6.8 18.3 6.7 18.4 6.5L19 5.4C19.1 5.3 19.1 5.2 19 5 19 4.9 18.9 4.8 18.8 4.7L13 1.7C12.9 1.7 12.8 1.7 12.8 1.7zM13.8 16.1C13.6 16.1 13.4 16 13.2 16L7.3 13C7 12.8 6.7 12.5 6.6 12.2 6.5 11.8 6.5 11.5 6.7 11.1L7.3 10C7.5 9.5 8 9.2 8.6 9.2 8.8 9.2 9 9.3 9.2 9.3L15 12.2C15.3 12.4 15.6 12.7 15.7 13 15.8 13.4 15.8 13.7 15.6 14.1L15 15.2C14.8 15.8 14.3 16.1 13.8 16.1zM8.5 10.2C8.3 10.2 8.2 10.3 8.1 10.5L7.5 11.6C7.4 11.7 7.4 11.8 7.5 12 7.5 12.1 7.6 12.2 7.7 12.3L13.5 15.2C13.7 15.3 14 15.2 14.1 15L14.7 13.9C14.8 13.8 14.8 13.7 14.7 13.5 14.7 13.4 14.6 13.3 14.5 13.2L8.7 10.3C8.7 10.2 8.6 10.2 8.5 10.2zM4.4 12.2C4.3 12.2 4.2 12.2 4.1 12.1L3.2 11.2C3 11 3 10.7 3.2 10.5 3.4 10.3 3.7 10.3 3.9 10.5L4.8 11.4C5 11.6 5 11.9 4.8 12.1 4.6 12.1 4.5 12.2 4.4 12.2zM2 17.8L1.1 17.8C.8 17.8.6 17.6.6 17.3.6 17 .8 16.8 1.1 16.8L2 16.8C2.3 16.8 2.5 17 2.5 17.3 2.5 17.6 2.3 17.8 2 17.8zM3.4 15C3.3 15 3.3 15 3.2 15L.9 14C.7 13.9.5 13.6.6 13.4.7 13.2 1 13 1.2 13.1L3.5 14C3.7 14.1 3.9 14.4 3.8 14.6 3.8 14.9 3.6 15 3.4 15z" />
              <path d="M24.6,16.4 C24.5,16.4 24.5,16.4 24.4,16.4 C24.2,16.3 24.1,16 24.2,15.8 L25.6,13 C25.7,12.8 26,12.7 26.2,12.8 C26.4,12.9 26.5,13.2 26.4,13.4 L25,16.1 C24.9,16.3 24.7,16.4 24.6,16.4 Z" />
              <g transform="translate(8 3)">
                <path d="M3.8 1.1C3.7 1 3.6 1 3.5.9L.6 6.7C.7 6.7.8 6.7 1 6.8L1.5 7.1 4.3 1.5 3.8 1.1zM9.6 4.1L9.1 3.8 6.3 9.4 6.8 9.7C6.9 9.8 7 9.8 7.1 9.9L10 4.1C9.8 4.2 9.7 4.1 9.6 4.1z" />
              </g>
            </g>
          </svg>
          )}
        </span>
      </h2>
      <div className={(!readMore && textLongerThanContainer) ? styles.contentContainer : styles.contentContainerFull}>
        <div className={styles.practiceTagContainer}>
          {itemsPracAreaGroups.map((itemsPracAreaGroup) => (
            <div className={styles.separateTags}> 
            {itemsPracAreaGroup?.map(
              (practiceAreaSnipper) => {
                const templateName = practiceAreaSnipper?.template?.templateName;
                const PracAreaName = practiceAreaSnipper?.template
                  ?.practiceChildConfiguration?.eaPracticeShortName;
                return (
                  <span className={styles.practiceTag} key={practiceAreaSnipper.id}>
                    {templateName === 'Practice Parent' || templateName === 'Default'
                      ? (practiceAreaSnipper?.title)
                      : (PracAreaName)}
                  </span>
                );
              },
            )}
            </div>
          )
          )}
        </div>
        <div className={styles.attorneysContainer}>
          {!!lawyersImages.length ? (
          <div className={styles.attorneysImages} style={{
            width: `${(49 + 30*(lawyersImages.length - 1))}px`,
          }}>
            {lawyersImages?.map((lawyerImage, i) => (
              lawyerImage && (<GatsbyImage
                loading="eager"
                image={getImage(lawyerImage.image)}
                src={lawyerImage.src}
                alt={lawyerImage.altText}
                className={styles.attorneyImage}
                style={{
                  left: `${(30*i)}px`,
                  zIndex: -i,
                  position: 'absolute',
                }}
              />)
            ))}
          </div>
          ):
          <StaticImage
            loading="eager"
            src="../../images/small-logo.png"
            alt="ROBINSON & HENRY Logo"
            className={styles.attorneyImage}
            style={{
              width: `45px`,
              height: `45px`
            }}
          />
          }
          <div className={styles.attorneysTitlesContainer}>
            <span className={styles.attorneyText}>{lawyers?.length > 1 ? 'Attorneys:' : 'Attorney:'}</span>
            <div className={styles.attorneysTitles}>
              {lawyers?.length > 0 ? (lawyers.map((lawyer, i) => (
                <Link key={lawyer.title} to={lawyer.uri} className={styles.attorney}>
                  {lawyer.title}
                  {i + 1 < lawyers.length && ', ' }
                </Link>
              ))) : (
                <Link key="R&H" to="/about" className={styles.attorney}>
                  ROBINSON & HENRY
                </Link>
              )}
            </div>
          </div>
        </div>
        <div
          className={readMore ? styles.content : styles.shortContent}
          dangerouslySetInnerHTML={{ __html: `<p>${contentAux}</p>` }}
        />
        {textLongerThanContainer && !readMore && (
          <button 
            type="button" 
            onClick={() => setReadMore(true)} 
            className={styles.readMoreButton}
          >
            Read more
          </button>
        )}
        {snippetData?.beCrSnippetClickthroughUrl && (readMore || !textLongerThanContainer) &&(
        <div className={styles.more}>
          <Link
            to={snippetData.beCrSnippetClickthroughUrl}
          >
            {snippetData.beCrSnippetClickthroughText}
          </Link>
        </div>
        )}

      </div>
    </article>
  );
}

SnippetCard.propTypes = {
  data: object,
  attorneys: array,
  fromTabCase: bool,
};

SnippetCard.defaultProps = {
  data: {},
  attorneys: [],
  fromTabCase: false,
};

export { SnippetCard };
