import {
  bool, func, number, string,
} from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './index.module.scss';

function Pagination({
  page, setPage, numPages, isLink, toPage,
}) {
  return (
    <nav className={styles.navigation} aria-label="Posts">
        {page < numPages
          && (
            <div className={styles.previous}>
              {isLink ? (
                <Link className={styles.button} to={toPage ? `${toPage}${page + 1}` : `/blog/${page + 1}`}>
                  Older posts
                </Link>
              ) : (
                <button
                  className={styles.button}
                  type="button"
                  onClick={() => setPage(page + 1)}
                >
                  Older posts
                </button>
              )}
            </div>
          )}
        {page > 1
        && (
          <div className={styles.next}>
            {isLink ? (
              <>
                {toPage ? (
                  <Link className={styles.button} to={page === 2 ? `${toPage}` : `${toPage}${page - 1}`}>
                    Newer posts
                  </Link>
                )
                  : (
                    <Link className={styles.button} to={page === 2 ? '/blog/' : `/blog/${page - 1}`}>
                      Newer posts
                    </Link>
                  )}
              </>
            ) : (
              <button
                className={styles.button}
                type="button"
                onClick={() => setPage(page - 1)}
              >
                Newer posts
              </button>
            )}
          </div>
        )}
    </nav>
  );
}

Pagination.defaultProps = {
  page: 0,
  numPages: 0,
  isLink: false,
  toPage: '',
};

Pagination.propTypes = {
  page: number,
  setPage: func.isRequired,
  numPages: number,
  isLink: bool,
  toPage: string,
};

export { Pagination };
