// extracted by mini-css-extract-plugin
export var article = "snippet-card-module--article--7adb0";
export var attorney = "snippet-card-module--attorney--530a8";
export var attorneyImage = "snippet-card-module--attorney-image--b053a";
export var attorneyText = "snippet-card-module--attorney-text--708cf";
export var attorneysContainer = "snippet-card-module--attorneys-container--cc34a";
export var attorneysImages = "snippet-card-module--attorneys-images--b85aa";
export var attorneysTitlesContainer = "snippet-card-module--attorneys-titles-container--c6015";
export var content = "snippet-card-module--content--a88d3";
export var contentContainer = "snippet-card-module--content-container--26a93";
export var contentContainerFull = "snippet-card-module--content-container-full--d7aef";
export var fromTabCase = "snippet-card-module--from-tab-case--8bbc9";
export var more = "snippet-card-module--more--67863";
export var practiceArea = "snippet-card-module--practice-area--145f4";
export var practiceTag = "snippet-card-module--practice-tag--dcb6a";
export var practiceTagContainer = "snippet-card-module--practice-tag-container--4bbe0";
export var readMoreButton = "snippet-card-module--read-more-button--ddf88";
export var separateTags = "snippet-card-module--separate-tags--cfc2d";
export var shortContent = "snippet-card-module--short-content--42b5f";
export var svg = "snippet-card-module--svg--cf45c";
export var titleCard = "snippet-card-module--titleCard--49a1f";