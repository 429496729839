import { object } from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Wrap } from '../../sections-wrap';
import { CaseOutcomes } from '../../case-outcomes';
import * as styles from './index.module.scss';

function CaseResults({ data }) {
  return (
    <>
      <div className={styles.imageContainer}>
        <StaticImage
          src="../../../images/case-outcomes-header.jpeg"
          width={1200}
          alt="Main image"
          className={styles.img}
        />
        <Wrap>
          <h1>Case Results</h1>
        </Wrap>
      </div>
      <CaseOutcomes data={data} />
    </>
  );
}

CaseResults.propTypes = {
  data: object,
};

CaseResults.defaultProps = {
  data: {},
};

export { CaseResults };
