import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { CaseResults } from '../components/pages/case-outcomes';

function CaseResultsTemplate({ data }) {
  return (
    <CaseResults data={data} />
  );
}

export const query = graphql`
query wpCaseResultsTemplate
  {
    allWpCategory {
        nodes {
          uri
          name
        }
      }
    allWpTeamMember {
      nodes {
        title
      }
    }
    caseResults: allWpCaseResultSnippet(sort: {fields: [caseResultSnippetSingleConfiguration___beCrSnippetFeatured, date], order: [ASC, DESC]}) {
      nodes {
        title
        uri
        content
        caseResultSnippetSingleConfiguration {
          beCrSnippetAttorney {
            ... on WpTeamMember {
              title
              uri
            }
          }
          beCrSnippetFeatured
          beCrSnippetClickthroughText
          beCrSnippetClickthroughUrl
          beCrSnippetPracticeArea {
            ... on WpPage {
              title
              template {
                templateName
                ... on WpTemplate_PracticeChild {
                  practiceChildConfiguration {
                    eaPracticeShortName
                  }
                }
                ... on WpTemplate_PracticeParent {
                  practiceParentConfiguration {
                    eaPracticeShortName
                  }
                }
              }
            }
          }
        }
      }
    }
    attorneys: allContentfulPageAttorney {
      nodes {
        title
        uri
        attorney{
          attorneyProfileImage {
            image {
              gatsbyImage(width:45, placeholder: NONE)
              file {
                url
              }
            }
            altText
          }
          firstName
          lastName
        }
      }
    }
  }
`;

CaseResultsTemplate.propTypes = {
  data: object,
};

CaseResultsTemplate.defaultProps = {
  data: {},
};

export default CaseResultsTemplate;
export { Head } from '../components/seo/seo';
