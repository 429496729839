import { object } from 'prop-types';
import { useState, useEffect } from 'react';
import { addNotDuplicatedArrayString } from '../../helpers';
import { Wrap } from '../sections-wrap';
import { SnippetCard } from '../snippet-card';
import { Pagination } from '../common/pagination';
import * as styles from './case-outcomes.module.scss';
import { getQueryParams, setQueryParams } from '../../helpers/misc';
import { Select } from '../common/custom-select';
import * as selectStyles from '../common/custom-select/select.module.scss';

function CaseOutcomes({ data }) {
  const paValuesFromSnippets = (snippetsLst) => {
    let pracAreaValuesSelect = [];

    snippetsLst.forEach((snippet) => {
      snippet.caseResultSnippetSingleConfiguration.beCrSnippetPracticeArea?.forEach((pa) => {
        pracAreaValuesSelect = addNotDuplicatedArrayString(
          pracAreaValuesSelect,
          pa.template.practiceChildConfiguration
            ? pa.template.practiceChildConfiguration.eaPracticeShortName : pa.title,
        );
      });
    });
    pracAreaValuesSelect?.sort((aTitle, bTitle) => (aTitle?.localeCompare(bTitle)));

    return pracAreaValuesSelect;
  };

  const attorneysValuesFromSnippets = (snippetsLst) => {
    let attorneysValuesSelect = [];
    snippetsLst.forEach((snippet) => {
      snippet.caseResultSnippetSingleConfiguration.beCrSnippetAttorney?.forEach((att) => {
        attorneysValuesSelect = addNotDuplicatedArrayString(attorneysValuesSelect, att.title);
      });
    });
    attorneysValuesSelect.sort((aName, bName) => {
      const aSplitted = aName.split(' ');
      const bSplitted = bName.split(' ');
      return (
        (
          (
            (aSplitted[aSplitted.length - 1] < bSplitted[bSplitted.length - 1]) && !aName.includes('Mike')
          )
          || bName.includes('Mike')
            ? -1 : 1
        )
      );
    });
    return attorneysValuesSelect;
  };

  const [page = 1, setPage] = useState(1);
  const [attorney, setAttorney] = useState('');
  const [practiceArea, setPracticeArea] = useState('');
  const [attorneysValues, setAttorneysValues] = useState(
    attorneysValuesFromSnippets(data?.caseResults?.nodes),
  );
  const [practiceAreaValues, setPracticeAreaValues] = useState(
    paValuesFromSnippets(data?.caseResults?.nodes),
  );

  const [snippets, setSnippets] = useState(data?.caseResults?.nodes);
  const postsPerPage = 10;

  const [isOpenPracticeArea, setIsOpenPracticeArea] = useState(false);

  useEffect(() => {
    const urlParams = getQueryParams();
    if (urlParams) {
      setPage(Number(urlParams.get('page')) || 1);
      setPracticeArea(urlParams.get('practiceArea'));
      setAttorney(urlParams.get('attorney'));
    }
  }, []);

  // Bill 19/8/22: Don't apply both filters at the same time
  const setAttorneyFn = (newAttorney) => {
    setAttorney(newAttorney);
    setQueryParams('practiceArea', '');
    setQueryParams('attorney', newAttorney);

    if (newAttorney === '') {
      setSnippets(data?.caseResults?.nodes);
      setAttorneysValues(attorneysValuesFromSnippets(data?.caseResults?.nodes));
    }
    if (practiceArea) {
      setPracticeArea('');
    }
  };
  const setPracticeAreaFn = (newPracticeArea) => {
    setIsOpenPracticeArea(false);
    setPracticeArea(newPracticeArea);
    setQueryParams('attorney', '');
    setQueryParams('practiceArea', newPracticeArea);
    if (newPracticeArea === '') {
      setSnippets(data?.caseResults?.nodes);
      setAttorneysValues(attorneysValuesFromSnippets(data?.caseResults?.nodes));
    }
    if (attorney) {
      setAttorney('');
    }
  };

  const numPages = Math.ceil(snippets.length / postsPerPage);

  useEffect(() => {
    if (attorney) {
      let auxSnippets = data?.caseResults?.nodes;
      setAttorneysValues(attorneysValuesFromSnippets(auxSnippets));
      auxSnippets = data?.caseResults?.nodes.filter(
        (snippet) => snippet.caseResultSnippetSingleConfiguration.beCrSnippetAttorney?.filter(
          (node) => node.title.toString() === attorney,
        ).length,
      );
      if (!practiceArea) {
        setPracticeAreaValues(paValuesFromSnippets(data?.caseResults?.nodes));
      } else {
        setPracticeAreaValues(paValuesFromSnippets(auxSnippets));
      }
      setSnippets(auxSnippets);
    }
  }, [attorney]);

  useEffect(() => {
    if (practiceArea) {
      let auxSnippets = data?.caseResults?.nodes;
      setPracticeAreaValues(paValuesFromSnippets(auxSnippets));

      auxSnippets = data?.caseResults?.nodes.filter(
        (snippet) => snippet.caseResultSnippetSingleConfiguration.beCrSnippetPracticeArea?.filter(
          (node) => node.title.toString() === practiceArea
            || node.template?.practiceChildConfiguration?.eaPracticeShortName === practiceArea,
        ).length,
      );
      setAttorneysValues(attorneysValuesFromSnippets(auxSnippets));
      setSnippets(auxSnippets);
    }
  }, [practiceArea]);

  const pageSnippets = snippets?.slice(page * postsPerPage - postsPerPage, page * postsPerPage);

  return (
    <main>
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.dataContainer}>
            <div className={styles.filtersContainer}>
              <div className={styles.filter}>
                <h3 className={styles.label}>Practice Area:</h3>
                <div className={styles.selectContainer}>
                  <Select
                    label="Practice Area"
                    firstOption="Any"
                    value={practiceArea}
                    className={styles.centeringSelect}
                    onChange={setPracticeAreaFn}
                    onChangeFirstOption={() => setPracticeAreaFn('')}
                    isOpen={isOpenPracticeArea}
                    setIsOpen={setIsOpenPracticeArea}
                  >
                    {practiceAreaValues?.map((item) => (
                      <button
                        onClick={() => setPracticeAreaFn(item)}
                        type="button"
                        className={item === practiceArea
                          ? selectStyles.selected
                          : selectStyles.item}
                      >
                        <p className={selectStyles.text}>{item}</p>
                      </button>
                    ))}
                  </Select>
                </div>
              </div>
              <div className={styles.filter}>
                <h3 className={styles.label}>Attorney:</h3>
                <div className={styles.selectContainer}>
                  <Select
                    label="Attorney"
                    firstOption="Any"
                    value={attorney}
                    className={styles.centeringSelect}
                    onChange={setAttorneyFn}
                    onChangeFirstOption={() => setAttorneyFn('')}
                  >
                    {attorneysValues?.map((item) => (
                      <button
                        onClick={() => setAttorneyFn(item)}
                        type="button"
                        className={item === attorney ? selectStyles.selected : selectStyles.item}
                      >
                        <p className={selectStyles.text}>{item}</p>
                      </button>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className={styles.teamGrid}>
              <div className={styles.disclaimer}>
                Past results afford no guarantee of future results; each matter is different and
                must be judged on its own merits.
              </div>
              {pageSnippets?.map((caseResult) => (
                <SnippetCard
                  key={caseResult.title}
                  data={caseResult}
                  attorneys={data?.attorneys}
                />
              ))}
            </div>
            <Pagination page={page} setPage={setPage} numPages={numPages} />
          </main>
        </Wrap>
      </div>
    </main>
  );
}

CaseOutcomes.propTypes = {
  data: object,
};

CaseOutcomes.defaultProps = {
  data: {},
};

export { CaseOutcomes };
